<template>
    <div>
        <button class="inline-block lg:hidden mdi mdi-menu text-4xl cursor-pointer border rounded-full bg-primary text-white focus:outline-none focus:ring focus:ring-gray-900 focus:ring-opacity-40 w-16 h-16 fixed z-40 bottom-4 right-4 select-none" @click="visible = true"></button>
        <div class="flex flex-col justify-center fixed top-0 w-screen h-screen overflow-auto bg-gray-900 transition-all z-50 py-8" :class="visible ? 'left-0' : '-left-screen'">
            <ul class="block text-center">
                <li class="block" v-for="(item, index) in data" :key="item.Id" @click="toggleSub(index)">
                    <span class="block text-3xl px-8 py-2" :class="isActive(item) ? 'text-secondary font-bold' : 'text-white'" v-if="Array.isArray(item.Sub)">{{ item.Name }}</span>
                    <a v-else class="block text-3xl px-8 py-2" :class="isActive(item) ? 'text-secondary font-bold' : 'text-white'" :href="item.Link" :target="item.IsNewWindow == 1 ? '_blank' : '_self'">{{ item.Name }}</a>
                    <ul v-if="Array.isArray(item.Sub)" class="block bg-white bg-opacity-5 overflow-hidden transition-all duration-500" :class="index === sub ? 'max-h-screen' : 'max-h-0'">
                        <li v-for="sub in item.Sub" :key="sub.Id" class="block">
                            <a class="block text-3xl px-8 py-2" :class="isActive(sub) ? 'text-secondary font-bold' : 'text-white'" :href="sub.Link" :target="sub.IsNewWindow == 1 ? '_blank' : '_self'">{{ sub.Name }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div></div>
        </div>
        <button class="mdi mdi-close text-4xl border border-white rounded-full border-opacity-20 text-white fixed z-50 bottom-4 transition-all cursor-pointer focus:outline-none focus:ring focus:ring-red-600 bg-red-600 focus:ring-opacity-40 w-16 h-16 select-none" :class="visible ? 'right-4 left-auto' : '-left-full'" @click="visible = false"></button>
    </div>
</template>

<script>

export default {
    name: "MobileMenu",
    props: ["data", "active", "id"],
    data() {
        return {
            url: window.location.protocol + "//" + window.location.host,
            visible: false,
            sub: -1
        };
    },
    methods: {
        toggleSub(index) {
            if (this.sub == index) {
                this.sub = -1;
            } else {
                this.sub = index;
            }
        },
        isActive(item) {
            return (this.active == 'subpage' && this.id == item.SubPageId || this.active == 'offers' && item.SubPageId == -1 || this.active == 'realizations' && item.SubPageId == -2) ? true : false;
        }
    }
};
</script>

<style>
</style>