<template>
    <div v-if="active" style="width: 540px; max-width: 90%" class="rounded-full bg-gray-900 text-white fixed mx-auto left-0 right-0 py-4 px-6 flex items-center justify-between max-w-full shadow transition-all duration-500" :class="show ? 'bottom-8' : '-bottom-24'">
        <div class="mr-3 tracking-wide text-sm sm:text-base">Korzystając z serwisu zgadzasz się z polityką cookies</div>
        <button class="py-2 px-5 text-center text-sm uppercase bg-blue-800 rounded-full font-bold cursor-pointer transition duration-300 focus:outline-none hover:bg-blue-700 focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 focus:ring-offset-2 focus:ring-offset-gray-900" @click="accept">ok</button>
    </div>
</template>

<script>
export default {
    name: "Cookies",
    data() {
        return {
            active: false,
            show: false
        }
    },
    methods: {
        accept() {
            this.show = false;
            this.$cookies.set("cookieinfo", "1", "1y", "/");
        }
    },
    created() {
        let cookie = this.$cookies.get("cookieinfo");
        if (cookie === null || cookie === "0") {
            this.show = true;
            this.active = true;
        }
    }
}
</script>

<style>

</style>